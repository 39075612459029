import { createBrowserRouter, Navigate } from "react-router-dom";
import HomeLayout from "../components/layouts/HomeLayout";
import AboutPage from "../components/pages/AboutPage";
import CrudTablePage from "../components/pages/CrudTablePage";
import GenericFormPage from "../components/pages/GenericFormPage";
import HomePage from "../components/pages/HomePage";
import LoginPage from "../components/pages/LoginPage";
import ProtectedRouteHome from "./ProtectedRouteHome";
import ProtectedRouteLogin from "./ProtectedRouteLogin";

const router = createBrowserRouter([
  {
    element: <ProtectedRouteHome />,
    children: [
      {
        path: "/",
        element: <HomeLayout />,
        children: [
          {
            index: true,
            element: <HomePage />,
            // loader: rootLoader,
          },
          {
            path: "/about",
            element: <AboutPage />,
          },
          {
            path: "/:module",
            element: <CrudTablePage />,
          },
          {
            path: "/:module/:type",
            element: <GenericFormPage />,
          },
        ],
      },
    ],
  },
  {
    element: <ProtectedRouteLogin />,
    children: [
      {
        path: "/login",
        element: <LoginPage />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
]);

export default router;
