import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import globalData from "./GlobalData";

const rootReducer = combineReducers({
  globalData: globalData,
});

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers = compose;

// export const rootStore = configureStore({
//   reducer: rootReducer,
// });

const generateStore = () => {
  // const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
  const store = configureStore({
    reducer: rootReducer,
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
  return store;
};

export default generateStore;
