import { Outlet, useLocation, useParams } from "react-router-dom";
import React, { ReactNode, useEffect, useState } from "react";
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Button,
  useColorMode,
  Center,
  Popover,
  PopoverTrigger,
  Stack,
  PopoverContent,
  Container,
  Image,
} from "@chakra-ui/react";
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from "react-icons/fi";
import { IconType } from "react-icons";
import {
  ChatIcon,
  ChevronRightIcon,
  MoonIcon,
  SunIcon,
} from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import SecureLS from "secure-ls";
import logoLine from "../../../assets/images/logo-line.svg";
import WrapperPage from "../../pages/WrapperPage";
import { systemConfigAction } from "../../../store/GlobalData";
import { useDispatch, useSelector } from "react-redux";
import { properties } from "../../../properties/properties";

const ls = new SecureLS({ encodingType: "aes" });

interface LinkItemProps {
  id: number;
  name: string;
  icon: IconType;
  href?: string;
}
// const LinkItemsBackup: Array<LinkItemProps> = [
//   { id: 1, name: "Inicio", icon: FiHome, href: "/" },
//   { id: 2, name: "Carreras", icon: FiTrendingUp, href: "/careers" },
//   { id: 3, name: "Materias", icon: FiCompass, href: "/subjects" },
//   { id: 4, name: "Profesores", icon: FiStar },
//   { id: 5, name: "Alumnos", icon: FiSettings },
//   { id: 6, name: "Actividades extraescolares", icon: FiSettings },
//   { id: 7, name: "Salones", icon: FiSettings },
//   { id: 8, name: "Usuarios", icon: FiSettings, href: "/users" },
//   { id: 9, name: "Jefes de división", icon: FiSettings },
//   { id: 10, name: "About", icon: FiTrendingUp, href: "/about" },
// ];

const HomeLayout = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedMenu, setSelectedMenu] = useState<any>();
  console.log("*_* location: ", location);
  const systemConfig = useSelector(
    (store: any) => store.globalData?.systemConfig
  );
  useEffect(() => {
    dispatch(systemConfigAction(properties));
  }, []);

  useEffect(() => {
    if (systemConfig) {
      const finded = systemConfig.sideBarMenu?.find(
        (item: any) => item.href === location.pathname
      );
      if (finded) {
        setSelectedMenu(finded);
      }
    }
  }, [systemConfig]);

  // useEffect(() => {
  //   ls.set("selectedMenu", selectedMenu);
  // }, [selectedMenu]);

  const signOff = () => {
    ls.remove("dUaStEaR");
    navigate("/login");
  };

  return (
    <WrapperPage>
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
          setSelectedMenu={setSelectedMenu}
          selectedMenu={selectedMenu}
          linkItems={systemConfig?.sideBarMenu || []}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent
              onClose={onClose}
              setSelectedMenu={setSelectedMenu}
              selectedMenu={selectedMenu}
              linkItems={systemConfig?.sideBarMenu || []}
            />
          </DrawerContent>
        </Drawer>

        {/* <Container width="100%" ml={{ base: 0, md: 60 }} p="4" backgroundColor="pink" height="100%">saasssssssssssssssssssssssssssssssss</Container> */}
        <MobileNav onOpen={onOpen} signOff={signOff} />
        <Box
          ml={{ base: 0, md: "260px" }}
          p="4"
          pt="110px"
          height="100vh"
          overflowY="auto"
        >
          <Outlet />
        </Box>
      </Box>
    </WrapperPage>
  );
};

export default HomeLayout;

interface SidebarProps extends BoxProps {
  onClose: () => void;
  setSelectedMenu: (data: any) => void;
  selectedMenu: any;
  linkItems: any[];
}

const SidebarContent = ({
  onClose,
  setSelectedMenu,
  selectedMenu,
  linkItems,
  ...rest
}: SidebarProps) => {
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      // bg={"#EDF2F7"}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: "260px" }}
      pos="fixed"
      h="full"
      {...rest}
      boxShadow="xl"
    >
      <Flex h="20" alignItems="center" mx="4" justifyContent="space-between">
        <Image width={"100%"} alt={"Login Image"} src={logoLine} />
        {/* <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
        </Text> */}
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {linkItems.map((link) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          href={link.href}
          setSelectedMenu={setSelectedMenu}
          selectedMenu={selectedMenu}
          link={link}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: any;
  setSelectedMenu: (data: any) => void;
  href?: string;
  link: any;
  selectedMenu: any;
}
const NavItem = ({
  href,
  icon,
  children,
  link,
  setSelectedMenu,
  selectedMenu,
  ...rest
}: NavItemProps) => {
  const navigate = useNavigate();
  return (
    <Link
      // href="#"
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
      onClick={() => {
        setSelectedMenu(link);
        if (href) {
          navigate(href);
        }
      }}
    >
      <Flex
        bg={selectedMenu?.id === link.id ? "cyan.400" : ""}
        color={selectedMenu?.id === link.id ? "white" : ""}
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "cyan.400",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
  signOff: () => void;
}
const MobileNav = ({ onOpen, signOff, ...rest }: MobileProps) => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Flex
      ml={{ base: 0, md: "260px" }}
      px={{ base: 4, md: 4 }}
      height="20"
      w={{ base: "100%", md: "calc(100% - 260px)" }}
      // width=""
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
      mt="10px"
      borderRadius="15px"
      position="fixed"
      zIndex="banner"
      boxShadow="xl"
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: "flex", md: "none" }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
      >
        Logo
      </Text>

      <HStack spacing={{ base: "0", md: "6" }}>
        <Popover trigger={"hover"} placement={"bottom-start"}>
          <PopoverTrigger>
            <IconButton
              size="lg"
              variant="ghost"
              aria-label="open menu"
              icon={<ChatIcon />}
            />
          </PopoverTrigger>

          <PopoverContent
            border={0}
            boxShadow={"xl"}
            bg={"white"}
            p={4}
            rounded={"xl"}
            minW={"sm"}
          >
            <Stack>
              <Link
                href={"#"}
                role={"group"}
                display={"block"}
                p={2}
                rounded={"md"}
                _hover={{ bg: useColorModeValue("blue.50", "gray.900") }}
              >
                <Stack direction={"row"} align={"center"}>
                  <Box>
                    <Text
                      transition={"all .3s ease"}
                      _groupHover={{ color: "blue.400" }}
                      fontWeight={500}
                    >
                      Mensaje
                    </Text>
                    <Text fontSize={"sm"}>Contenido mensaje...</Text>
                  </Box>
                  <Flex
                    transition={"all .3s ease"}
                    transform={"translateX(-10px)"}
                    opacity={0}
                    _groupHover={{
                      opacity: "100%",
                      transform: "translateX(0)",
                    }}
                    justify={"flex-end"}
                    align={"center"}
                    flex={1}
                  >
                    <Icon
                      color={"blue.400"}
                      w={5}
                      h={5}
                      as={ChevronRightIcon}
                    />
                  </Flex>
                </Stack>
              </Link>
              <Link
                href={"#"}
                role={"group"}
                display={"block"}
                p={2}
                rounded={"md"}
                _hover={{ bg: useColorModeValue("blue.50", "gray.900") }}
              >
                <Stack direction={"row"} align={"center"}>
                  <Box>
                    <Text
                      transition={"all .3s ease"}
                      _groupHover={{ color: "blue.400" }}
                      fontWeight={500}
                    >
                      Mensaje
                    </Text>
                    <Text fontSize={"sm"}>Contenido mensaje...</Text>
                  </Box>
                  <Flex
                    transition={"all .3s ease"}
                    transform={"translateX(-10px)"}
                    opacity={0}
                    _groupHover={{
                      opacity: "100%",
                      transform: "translateX(0)",
                    }}
                    justify={"flex-end"}
                    align={"center"}
                    flex={1}
                  >
                    <Icon
                      color={"blue.400"}
                      w={5}
                      h={5}
                      as={ChevronRightIcon}
                    />
                  </Flex>
                </Stack>
              </Link>
              <Link
                href={"#"}
                role={"group"}
                display={"block"}
                p={2}
                rounded={"md"}
                _hover={{ bg: useColorModeValue("blue.50", "gray.900") }}
              >
                <Stack direction={"row"} align={"center"}>
                  <Box>
                    <Text
                      transition={"all .3s ease"}
                      _groupHover={{ color: "blue.400" }}
                      fontWeight={500}
                    >
                      Mensaje
                    </Text>
                    <Text fontSize={"sm"}>Contenido mensaje...</Text>
                  </Box>
                  <Flex
                    transition={"all .3s ease"}
                    transform={"translateX(-10px)"}
                    opacity={0}
                    _groupHover={{
                      opacity: "100%",
                      transform: "translateX(0)",
                    }}
                    justify={"flex-end"}
                    align={"center"}
                    flex={1}
                  >
                    <Icon
                      color={"blue.400"}
                      w={5}
                      h={5}
                      as={ChevronRightIcon}
                    />
                  </Flex>
                </Stack>
              </Link>
            </Stack>
          </PopoverContent>
        </Popover>

        <Popover trigger={"hover"} placement={"bottom-start"}>
          <PopoverTrigger>
            <IconButton
              size="lg"
              variant="ghost"
              aria-label="open menu"
              icon={<FiBell />}
            />
          </PopoverTrigger>

          <PopoverContent
            border={0}
            boxShadow={"xl"}
            bg={"white"}
            p={4}
            rounded={"xl"}
            minW={"sm"}
          >
            <Stack>
              <Link
                href={"#"}
                role={"group"}
                display={"block"}
                p={2}
                rounded={"md"}
                _hover={{ bg: useColorModeValue("blue.50", "gray.900") }}
              >
                <Stack direction={"row"} align={"center"}>
                  <Box>
                    <Text
                      transition={"all .3s ease"}
                      _groupHover={{ color: "blue.400" }}
                      fontWeight={500}
                    >
                      Notificación
                    </Text>
                    <Text fontSize={"sm"}>Contenido de la notifiación...</Text>
                  </Box>
                  <Flex
                    transition={"all .3s ease"}
                    transform={"translateX(-10px)"}
                    opacity={0}
                    _groupHover={{
                      opacity: "100%",
                      transform: "translateX(0)",
                    }}
                    justify={"flex-end"}
                    align={"center"}
                    flex={1}
                  >
                    <Icon
                      color={"blue.400"}
                      w={5}
                      h={5}
                      as={ChevronRightIcon}
                    />
                  </Flex>
                </Stack>
              </Link>
              <Link
                href={"#"}
                role={"group"}
                display={"block"}
                p={2}
                rounded={"md"}
                _hover={{ bg: useColorModeValue("blue.50", "gray.900") }}
              >
                <Stack direction={"row"} align={"center"}>
                  <Box>
                    <Text
                      transition={"all .3s ease"}
                      _groupHover={{ color: "blue.400" }}
                      fontWeight={500}
                    >
                      Notificación
                    </Text>
                    <Text fontSize={"sm"}>Contenido de la notifiación...</Text>
                  </Box>
                  <Flex
                    transition={"all .3s ease"}
                    transform={"translateX(-10px)"}
                    opacity={0}
                    _groupHover={{
                      opacity: "100%",
                      transform: "translateX(0)",
                    }}
                    justify={"flex-end"}
                    align={"center"}
                    flex={1}
                  >
                    <Icon
                      color={"blue.400"}
                      w={5}
                      h={5}
                      as={ChevronRightIcon}
                    />
                  </Flex>
                </Stack>
              </Link>
              <Link
                href={"#"}
                role={"group"}
                display={"block"}
                p={2}
                rounded={"md"}
                _hover={{ bg: useColorModeValue("blue.50", "gray.900") }}
              >
                <Stack direction={"row"} align={"center"}>
                  <Box>
                    <Text
                      transition={"all .3s ease"}
                      _groupHover={{ color: "blue.400" }}
                      fontWeight={500}
                    >
                      Notificación
                    </Text>
                    <Text fontSize={"sm"}>Contenido de la notifiación...</Text>
                  </Box>
                  <Flex
                    transition={"all .3s ease"}
                    transform={"translateX(-10px)"}
                    opacity={0}
                    _groupHover={{
                      opacity: "100%",
                      transform: "translateX(0)",
                    }}
                    justify={"flex-end"}
                    align={"center"}
                    flex={1}
                  >
                    <Icon
                      color={"blue.400"}
                      w={5}
                      h={5}
                      as={ChevronRightIcon}
                    />
                  </Flex>
                </Stack>
              </Link>
            </Stack>
          </PopoverContent>
        </Popover>

        <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
          onClick={toggleColorMode}
        />
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar
                  size={"sm"}
                  src={
                    "https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
                  }
                />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">Justina Clark</Text>
                  <Text fontSize="xs" color="gray.600">
                    Admin
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <MenuItem>Profile</MenuItem>
              <MenuItem>Settings</MenuItem>
              <MenuItem>Billing</MenuItem>
              <MenuDivider />
              <MenuItem onClick={() => signOff()}>Sign out</MenuItem>
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton
              as={Button}
              rounded={"full"}
              variant={"link"}
              cursor={"pointer"}
              minW={0}
            >
              <Avatar
                size={"sm"}
                src={"https://avatars.dicebear.com/api/male/username.svg"}
              />
            </MenuButton>
            <MenuList alignItems={"center"}>
              <br />
              <Center>
                <Avatar
                  size={"2xl"}
                  src={"https://avatars.dicebear.com/api/male/username.svg"}
                />
              </Center>
              <br />
              <Center>
                <p>Username</p>
              </Center>
              <br />
              <MenuDivider />
              <MenuItem>Your Servers</MenuItem>
              <MenuItem>Account Settings</MenuItem>
              <MenuItem onClick={() => signOff()}>Logout</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};
