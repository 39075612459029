import axios from "axios";

export const apiTest = (status: number, data: any) => {
  return new Promise<any>((resolve, reject) => {
    axios.post(
      "https://us-central1-verbos-en-ingles-c4268.cloudfunctions.net/app/test/api-test",
      { status, data }
    ).then(response => {
      resolve(response.data)
    }).catch(error => {
      reject(error)
    });
  })
};
