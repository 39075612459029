import LoginContainer from "../../containers/LoginContainer";
import WrapperPage from "../WrapperPage";

const LoginPage = () => {
    return (
    <WrapperPage>
        <LoginContainer />
    </WrapperPage>)
}

export default LoginPage;