import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { IGeneralAlertProps } from "../../interfaces/IGeneralAlert";
import { useSelector, useDispatch } from "react-redux";
import { generalModalAction } from "../../../store/GlobalData";
import GenericForm from "../../organisms/GenericForm";

const GeneralModal = () => {
  const sizes = ["xs", "sm", "md", "lg", "xl", "full"];

  const dispatch = useDispatch();
  const generalModal = useSelector(
    (store: any) => store.globalData?.generalModal
  );
  console.log("*_* generalModal: ", generalModal, !!generalModal);

  const handleActionGenericForm = (dataForm: any) => {
    console.log("*_* handleActionGenericForm: ", dataForm);
  };

  return (
    <Modal
      onClose={() => {
        if (generalModal.actionClose) {
          generalModal.actionClose();
        } else {
          dispatch(generalModalAction(null));
        }
      }}
      size={generalModal?.size}
      isOpen={!!generalModal}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{generalModal?.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {generalModal?.type === "text" ? (
            <>{generalModal?.text}</>
          ) : generalModal?.type === "genericForm" ? (
            <GenericForm 
            formId={generalModal?.configGenericForm?.formId}
            onlyInputs={generalModal?.configGenericForm?.onlyInputs}
            initData={generalModal?.configGenericForm?.initData}
            inputs={generalModal?.configGenericForm?.inputs}
            actions={generalModal?.configGenericForm?.actions}
            handleAction={handleActionGenericForm} />
          ) : generalModal?.type === "customComponent" ? (
            <>{generalModal?.component()}</>
          ) : (
            <>No se encontro el tipo de modal</>
          )}
        </ModalBody>
        <ModalFooter>
          {/* <Button onClick={onClose}>Close</Button> */}
          {generalModal?.actions?.length > 0
            ? generalModal?.actions.map((item: any, index: number) => (
                <Button
                  key={`generalModal-button-${index}`}
                  colorScheme={item.color}
                  ml={3}
                  onClick={() => {
                    if (item.handleAction) {
                      item.handleAction();
                    }
                  }}
                >
                  {item.title}
                </Button>
              ))
            : null}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GeneralModal;
