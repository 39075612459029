import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import LoginT2 from "../../organisms/LoginT2";
import LoginT1 from "../../organisms/LoginT1";
import { useNavigate } from "react-router-dom";
import SecureLS from "secure-ls";
import { generalAlertAction, systemConfigAction } from "../../../store/GlobalData";
import { useToast } from "@chakra-ui/react";
import LoginT3 from "../../organisms/LoginT3";
import { apiTest } from "../../../services/test.service";
import { properties } from "../../../properties/properties";

const StyleLoginContainer = styled((props: any) => <div {...props} />)`
  width: 100%;
  height: 100%;
`;

interface IProps {}

const ls = new SecureLS({ encodingType: "aes" });
const dataDummy = [
  {
    email: "profesor@cs.com",
    pass: "profesor",
    user: "El profe",
    rol: "profesor",
    permissions: [""],
  },
  {
    email: "admin@cs.com",
    user: "Admin",
    rol: "admin",
    permissions: [""],
  },
  {
    email: "alumno@cs.com",
    user: "El alumno",
    rol: "alumno",
    permissions: [""],
  },
];

const LoginContainer = ({}: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  // let history = useHistory()
  const [typeLogin, setTypeLogin] = useState<"loginT1" | "loginT2" | "loginT3">(
    "loginT1"
  );

  useEffect(() => {
    apiTest(200, properties).then((response) => {
      console.log("*_* apiTest: ", response);
      dispatch(systemConfigAction(response))
    });
  }, []);

  const handleRedirectToHome = () => {
    navigate("/");
    // toggleLoading();
  };

  const tryAgainGeneralAction = () => {
    dispatch(generalAlertAction(null));
    console.log("*_* tryAgainGeneralAction: ");
  };

  const handleLogin = (data: any) => {
    console.log("*_* handleLogin: ", data);

    setIsSubmitting(true);
    setTimeout(() => {
      let flag = false;
      let dataUser: any = {};
      for (const item of dataDummy) {
        if (item.email === data.email && item.pass === data.password) {
          flag = true;
          dataUser = item;
        }
      }

      setIsSubmitting(false);
      if (flag) {
        ls.set("dUaStEaR", dataUser);
        handleRedirectToHome();
        toast({
          title: `Bienvenido ${dataUser.user}`,
          // description: "We've created your account for you.",
          status: "success",
          duration: 4000,
          position: "bottom-right",
          isClosable: true,
          variant: ["left-accent"],
        });
      } else {
        //lanzar error
        dispatch(
          generalAlertAction({
            title: "Error",
            text: "Usuario y/o contraseña incorrectos. Intenta nuevamente.",
            actions: [
              {
                title: "Intentar nuevamente",
                handleAction: tryAgainGeneralAction,
                color: "red",
              },
            ],
          })
        );
      }
    }, 1500);
  };

  return (
    <StyleLoginContainer>
      {typeLogin === "loginT3" ? (
        <LoginT3 handleLogin={handleLogin} />
      ) : typeLogin === "loginT2" ? (
        <LoginT2 handleLogin={handleLogin} />
      ) : (
        <LoginT1 handleLogin={handleLogin} isSubmitting={isSubmitting} />
      )}
    </StyleLoginContainer>
  );
};

export default LoginContainer;
