import { AddIcon } from "@chakra-ui/icons";
import { Box, Text } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Logo } from "../../../Logo";
import SecureLS from "secure-ls";
import GeneralTable from "../../molecules/GeneralTable";
import CrudTable from "../../organisms/CrudTable";
import { generalModalAction } from "../../../store/GlobalData";
import GenericForm from "../../organisms/GenericForm";

const dataDummy = [
  {
    type: "text",
  }
]

const GenericFormContainer = () => {
  const ls = new SecureLS({ encodingType: "aes" });
  const dispatch = useDispatch();
  const [configModule, setConfigModule] = useState<any>();
  const [dataTable, setDataTable] = useState<any>();
  const params: any = useParams();
  useEffect(() => {
    const selectedCrudTable = ls.get("selectedCrudTable")
    console.log("*_* selectedCrudTable: ", selectedCrudTable);
  }, []);

  const handleCrudTable = (data: any) => {
    console.log("*_* handleCrudTable: ", data);
    dispatch(generalModalAction({
      title: "Agregar carrera",
      type: "genericForm",
      configGenericForm: {},
      text: "jajajajjajajja",
      actions: [
        {
          title: "Cancelar",
          handleAction: handleCancelarModal,
          color: "red",
        },
        {
          title: "Agregar",
          color: "green",
        }
      ]
    }));
  };

  const handleCancelarModal = () => {
    dispatch(generalModalAction(null));
  }

  return (
    <Box textAlign="center" fontSize="xl" height="100%">
      {/* <GenericForm configGenericForm={dataDummy} /> */}
    </Box>
  );
};

export default GenericFormContainer;
