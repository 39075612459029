export const properties = {
  sideBarMenu: [
    { id: 1, name: "Inicio", icon: "FiHome", href: "/" },
    { id: 2, name: "Carreras", icon: "FiTrendingUp", href: "/careers" },
    { id: 3, name: "Materias", icon: "FiCompass", href: "/subjects" },
    { id: 4, name: "Profesores", icon: "FiStar", href: "/teachers" },
    { id: 5, name: "Alumnos", icon: "FiSettings" },
    { id: 6, name: "Actividades extraescolares", icon: "FiSettings" },
    { id: 7, name: "Salones", icon: "FiSettings" },
    { id: 8, name: "Usuarios", icon: "FiSettings", href: "/users" },
    { id: 9, name: "Jefes de división", icon: "FiSettings" },
    { id: 10, name: "About", icon: "FiTrendingUp", href: "/about" },
  ],
  careers: {
    title: "Carreras",
    withAddButton: true,
    configTable: {
      tableCaption: "Carreras actuales",
      tableCaptionLenght0: "No hay carreras agregadas",
      badges: [
        {
          key: "Activo",
          color: "green",
        },
        {
          key: "Inactivo",
          color: "red",
        },
      ],
      columns: [
        {
          header: {
            text: "ID",
            filter: "",
          },
          row: {
            type: "",
            keyValue: "id",
          },
        },
        {
          header: {
            text: "Carrera",
            filter: "",
          },
          row: {
            type: "",
            keyValue: "careerName",
          },
        },
        {
          header: {
            text: "Estatus",
            filter: "",
          },
          row: {
            type: "badge",
            keyValue: "status",
          },
        },
        {
          header: {
            text: "Actions",
            filter: "",
          },
          row: {
            type: "actions",
            actions: [
              {
                key: "detail",
                type: "detail"
              },
              {
                key: "edit",
                type: "edit"
              },
              {
                key: "delete",
                type: "delete"
              },
            ],
          },
        },
      ],
    },
    addForm: {
      title: "Agregar carrera",
      size: "md",
      placement: "right",
      configForm: {
        formId: "form-add-carreras",
        onlyInputs: true,
        initData: {},
        inputs: [
          {
            id: "careerName",
            type: "text",
            label: "Carrera",
            required: true,
            requiredText: "Requerido",
            variant: "flushed"
          },
          {
            id: "status",
            label: "Estatus",
            type: "select",
            required: true,
            requiredText: "Requerido",
            defaultValue: "activo",
            variant: "flushed",
            options: [
              {
                label: "Activo",
                value: "activo",
              },
              {
                label: "Inactivo",
                value: "inactivo",
              },
            ],
          },
        ],
      },
      actions: [
        {
          title: "Cancelar",
          color: "red",
        },
        {
          title: "Agregar",
          type: "submit",
          formId: "form-add-carreras",
          color: "green",
        },
      ],
    },
    editForm: {
      title: "Editar carrera",
      size: "md",
      placement: "right",
      configForm: {
        formId: "form-edit-carreras",
        onlyInputs: true,
        initData: {},
        inputs: [
          {
            id: "id",
            type: "label",
            label: "ID de carrera",
            defaultValue: "",
            disabled: true,
            variant: "flushed"
          },
          {
            id: "careerName",
            type: "text",
            label: "Carrera",
            required: true,
            requiredText: "Requerido",
            variant: "flushed"
          },
          {
            id: "status",
            label: "Estatus",
            type: "select",
            required: true,
            requiredText: "Requerido",
            defaultValue: "activo",
            variant: "flushed",
            options: [
              {
                label: "Activo",
                value: "activo",
              },
              {
                label: "Inactivo",
                value: "inactivo",
              },
            ],
          },
        ],
      },
      actions: [
        {
          title: "Cancelar",
          color: "red",
        },
        {
          title: "Agregar",
          type: "submit",
          formId: "form-edit-carreras",
          color: "green",
        },
      ],
    },
    detailForm: {
      title: "Detalle de la carrera",
      size: "md",
      placement: "right",
      configForm: {
        onlyInputs: true,
        initData: {},
        inputs: [
          {
            id: "id",
            type: "label",
            label: "ID de carrera",
            defaultValue: "",
            disabled: true,
            variant: "flushed"
          },
          {
            id: "careerName",
            type: "label",
            label: "Nombre de la carrera",
            defaultValue: "",
            disabled: true,
            variant: "flushed"
          },
          {
            id: "status",
            type: "label",
            label: "Estatus",
            defaultValue: "",
            disabled: true,
            variant: "flushed"
          },
        ],
      },
      actions: [
        {
          title: "Aceptar",
          type: "submit",
          actionType: "close",
          color: "green",
        },
      ],
    },
    alertDelete: {
      title: "Eliminar carrera",
      message: "¿Desea eliminar la carrera?",
    }
  },
  subjects: {
    title: "Materias",
    withAddButton: true,
    configTable: {
      tableCaption: "Materias actuales",
      tableCaptionLenght0: "No hay materias agregadas",
      badges: [
        {
          key: "Activo",
          color: "green",
        },
        {
          key: "Inactivo",
          color: "red",
        },
      ],
      columns: [
        {
          header: {
            text: "ID",
            filter: "",
          },
          row: {
            type: "",
            keyValue: "id",
          },
        },
        {
          header: {
            text: "Carrera",
            filter: "",
          },
          row: {
            type: "",
            keyValue: "subjectName",
          },
        },
        {
          header: {
            text: "Estatus",
            filter: "",
          },
          row: {
            type: "badge",
            keyValue: "status",
          },
        },
        {
          header: {
            text: "Actions",
            filter: "",
          },
          row: {
            type: "actions",
            actions: [
              {
                key: "edit",
                type: "edit",
              },
              {
                key: "delete",
                type: "delete",
              },
            ],
          },
        },
      ],
    },
    addForm: {
      title: "Agregar materia",
      size: "md",
      placement: "right",
      configForm: {
        formId: "form-add-materia",
        onlyInputs: true,
        initData: {},
        inputs: [
          {
            id: "subjetName",
            type: "text",
            label: "Materia",
            required: true,
            requiredText: "Requerido",
            variant: "flushed"
          },
          {
            id: "status",
            label: "Estatus",
            type: "select",
            required: true,
            requiredText: "Requerido",
            defaultValue: "activo",
            variant: "flushed",
            options: [
              {
                label: "Activo",
                value: "activo",
              },
              {
                label: "Inactivo",
                value: "inactivo",
              },
            ],
          },
        ],
      },
      actions: [
        {
          title: "Cancelar",
          color: "red",
        },
        {
          title: "Agregar",
          type: "submit",
          formId: "form-add-materia",
          color: "green",
        },
      ],
    },
    editForm: {
      title: "Editar carrera",
      size: "md",
      placement: "right",
      configForm: {
        formId: "form-edit-materia",
        onlyInputs: true,
        initData: {},
        inputs: [
          {
            id: "id",
            type: "label",
            label: "ID de materia",
            defaultValue: "",
            disabled: true,
            variant: "flushed"
          },
          {
            id: "subjectName",
            type: "text",
            label: "Carrera",
            required: true,
            requiredText: "Requerido",
            variant: "flushed"
          },
          {
            id: "status",
            label: "Estatus",
            type: "select",
            required: true,
            requiredText: "Requerido",
            defaultValue: "activo",
            variant: "flushed",
            options: [
              {
                label: "Activo",
                value: "activo",
              },
              {
                label: "Inactivo",
                value: "inactivo",
              },
            ],
          },
        ],
      },
      actions: [
        {
          title: "Cancelar",
          color: "red",
        },
        {
          title: "Agregar",
          type: "submit",
          formId: "form-edit-materia",
          color: "green",
        },
      ],
    },
    alertDelete: {
      title: "Eliminar materia",
      message: "¿Desea eliminar la materia?",
    }
  },
  teachers: {
    title: "Maestros",
    withAddButton: true,
    configTable: {
      tableCaption: "Maestros actuales",
      tableCaptionLenght0: "No hay maestros agregados",
      badges: [
        {
          key: "Activo",
          color: "green",
        },
        {
          key: "Inactivo",
          color: "red",
        },
      ],
      columns: [
        {
          header: {
            text: "ID",
            filter: "",
          },
          row: {
            type: "",
            keyValue: "id",
          },
        },
        {
          header: {
            text: "Nombre",
            filter: "",
          },
          row: {
            type: "",
            keyValue: "teacherName",
          },
        },
        {
          header: {
            text: "Edad",
            filter: "",
          },
          row: {
            type: "",
            keyValue: "age",
          },
        },
        {
          header: {
            text: "Genero",
            filter: "",
          },
          row: {
            type: "",
            keyValue: "gender",
          },
        },
        {
          header: {
            text: "Estatus",
            filter: "",
          },
          row: {
            type: "badge",
            keyValue: "status",
          },
        },
        {
          header: {
            text: "Actions",
            filter: "",
          },
          row: {
            type: "actions",
            actions: [
              {
                key: "edit",
                type: "edit",
              },
              {
                key: "delete",
                type: "delete",
              },
            ],
          },
        },
      ],
    },
    addForm: {
      title: "Agregar Maestro",
      size: "md",
      placement: "right",
      configForm: {
        formId: "form-add-maestro",
        onlyInputs: true,
        initData: {},
        inputs: [
          {
            id: "teacherName",
            type: "text",
            label: "Nombre",
            required: true,
            requiredText: "Requerido",
            variant: "flushed"
          },
          {
            id: "teacherLastName",
            type: "text",
            label: "Apellido paterno",
            required: true,
            requiredText: "Requerido",
            variant: "flushed"
          },
          {
            id: "teacherSecondName",
            type: "text",
            label: "Apellido materno",
            required: true,
            requiredText: "Requerido",
            variant: "flushed"
          },
          {
            id: "age",
            type: "text",
            label: "Edad",
            required: true,
            requiredText: "Requerido",
            variant: "flushed"
          },
          {
            id: "gender",
            label: "Genero",
            type: "select",
            required: true,
            requiredText: "Requerido",
            defaultValue: "",
            variant: "flushed",
            options: [
              {
                label: "Seleccionar",
                value: "",
              },
              {
                label: "Masculino",
                value: "masculino",
              },
              {
                label: "Femenino",
                value: "femenino",
              },
            ],
          },
          {
            id: "status",
            label: "Estatus",
            type: "select",
            required: true,
            requiredText: "Requerido",
            defaultValue: "activo",
            variant: "flushed",
            options: [
              {
                label: "Activo",
                value: "activo",
              },
              {
                label: "Inactivo",
                value: "inactivo",
              },
            ],
          },
        ],
      },
      actions: [
        {
          title: "Cancelar",
          color: "red",
        },
        {
          title: "Agregar",
          type: "submit",
          formId: "form-add-maestro",
          color: "green",
        },
      ],
    },
    editForm: {
      title: "Editar maestro",
      size: "md",
      placement: "right",
      configForm: {
        formId: "form-edit-aestro",
        onlyInputs: true,
        initData: {},
        inputs: [
          {
            id: "id",
            type: "label",
            label: "Nombre",
            variant: "flushed"
          },
          {
            id: "teacherName",
            type: "text",
            label: "Nombre",
            required: true,
            requiredText: "Requerido",
            variant: "flushed"
          },
          {
            id: "teacherLastName",
            type: "text",
            label: "Apellido paterno",
            required: true,
            requiredText: "Requerido",
            variant: "flushed"
          },
          {
            id: "teacherSecondName",
            type: "text",
            label: "Apellido materno",
            required: true,
            requiredText: "Requerido",
            variant: "flushed"
          },
          {
            id: "age",
            type: "text",
            label: "Edad",
            required: true,
            requiredText: "Requerido",
            variant: "flushed"
          },
          {
            id: "gender",
            label: "Genero",
            type: "select",
            required: true,
            requiredText: "Requerido",
            defaultValue: "",
            variant: "flushed",
            options: [
              {
                label: "Seleccionar",
                value: "",
              },
              {
                label: "Masculino",
                value: "masculino",
              },
              {
                label: "Femenino",
                value: "femenino",
              },
            ],
          },
          {
            id: "status",
            label: "Estatus",
            type: "select",
            required: true,
            requiredText: "Requerido",
            defaultValue: "activo",
            variant: "flushed",
            options: [
              {
                label: "Activo",
                value: "activo",
              },
              {
                label: "Inactivo",
                value: "inactivo",
              },
            ],
          },
        ],
      },
      actions: [
        {
          title: "Cancelar",
          color: "red",
        },
        {
          title: "Agregar",
          type: "submit",
          formId: "form-edit-maestro",
          color: "green",
        },
      ],
    },
    alertDelete: {
      title: "Eliminar maestro",
      message: "¿Desea eliminar al maestro?",
    }
  },
  users: {
    title: "Usuarios",
    withAddButton: true,
    configTable: {
      tableCaption: "Usuarios actuales",
      tableCaptionLenght0: "No hay usuarios agregados",
      badges: [
        {
          key: "Activo",
          color: "green",
        },
        {
          key: "Inactivo",
          color: "red",
        },
      ],
      columns: [
        {
          header: {
            text: "ID",
            filter: "",
          },
          row: {
            type: "",
            keyValue: "id",
          },
        },
        {
          header: {
            text: "Carrera",
            filter: "",
          },
          row: {
            type: "",
            keyValue: "career",
          },
        },
        {
          header: {
            text: "Estatus",
            filter: "",
          },
          row: {
            type: "badge",
            keyValue: "status",
          },
        },
        {
          header: {
            text: "Actions",
            filter: "",
          },
          row: {
            type: "actions",
            actions: [
              {
                key: "edit",
              },
              {
                key: "delete",
              },
            ],
          },
        },
      ],
    },
  },
};
