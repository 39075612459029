import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Container,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
  PinInput,
  PinInputField,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { CheckIcon, PhoneIcon } from "@chakra-ui/icons";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useEffect, useState } from "react";

const allInputs = [
  {
    id: "user",
    type: "text",
    label: "Usuario",
    required: true,
    requiredText: "Este es requerido",
    size: "xs",
    placeholder: "Nombre de usuario",
    variant: "filled",
    color: "cyan",
  },
  {
    id: "user2",
    type: "text",
    label: "Usuario",
    required: true,
    requiredText: "Este es requerido",
    size: "xl",
    placeholder: "Nombre de usuario",
    variant: "flushed",
    color: "red",
  },
  {
    id: "password",
    type: "password",
    label: "Contraseña",
    required: true,
    requiredText: "Requerido",
    size: "md",
    placeholder: "Escribe tu contraseña",
    variant: "flushed",
    inpRightElement: {
      type: "buttonPassText",
      text: "Mostrar",
      password: "Ocultar",
    },
  },
  {
    id: "repeatPassword",
    type: "password",
    label: "Repetir contraseña",
    required: true,
    requiredText: "Requerido",
    helperText: "Debe ser el mismo password",
    size: "lg",
    inpRightElement: {
      type: "buttonPassIcon",
      text: "Mostrar",
      password: "Ocultar",
    },
  },
  {
    id: "gender",
    type: "radio",
    defaultValue: "f",
    direction: "row",
    required: true,
    requiredText: "Gender es requerido",
    color: "red",
    options: [
      {
        label: "Femenino",
        value: "f",
        color: "green",
        disabled: true,
      },
      {
        label: "Masculino",
        value: "m",
        color: "blue",
      },
    ],
  },
  {
    id: "gender2",
    label: "Genero",
    type: "select",
    color: "blue",
    defaultValue: "",
    variant: "flushed",
    options: [
      {
        label: "Seleccionar",
        value: "",
      },
      {
        label: "Femenino",
        value: "f",
      },
      {
        label: "Masculino",
        value: "m",
      },
    ],
  },
  {
    id: "old",
    label: "Edad",
    type: "number",
    required: true,
    requiredText: "Requerido",
  },

  {
    id: "tel1",
    type: "tel",
    variant: "flushed",
    leftElement: {
      icon: "phone",
      iconColor: "gray.300",
    },
    rightElement: {
      icon: "check",
      iconColor: "green",
    },
  },
  {
    id: "tel2",
    type: "tel",
    leftAddon: {
      icon: "+52",
    },
    rightAddon: {
      icon: "+52",
    },
  },

  {
    id: "url",
    label: "URL",
    type: "url",
    leftAddon: {
      icon: "https://",
    },
    rightAddon: {
      icon: ".com",
    },
  },
  {
    id: "timePicker",
    label: "datetime-local",
    type: "datetime-local",
    variant: "flushed",
  },
  {
    id: "money",
    type: "text",
    size: "sm",
    variant: "flushed",
    leftElement: {
      icon: "$",
      color: "gray.300",
      fontSize: "1.2em",
    },
  },
  {
    id: "switch",
    label: "Switch",
    type: "switch",
    text: "Recibir notificaciones",
    textPosition: "top",
  },
  {
    id: "switch1",
    label: "Switch",
    type: "switch",
    text: "Recibir notificaciones",
    textPosition: "left",
  },
  {
    id: "switch2",
    label: "Switch",
    type: "switch",
    text: "Recibir notificaciones",
    textPosition: "right",
  },
  {
    id: "pin",
    type: "pin",
    subType: "alphanumeric",
    numberPin: 4,
    variant: "flushed",
    color: "blue",
    requiredText: "Pin requerido",
    required: true,
    minLength: 4,
    minLengthText: "Deben ser 4",
  },
  {
    id: "pin2",
    type: "pin",
    subType: "alphanumeric",
    numberPin: 4,
    variant: "flushed",
    color: "blue",
    mask: true,
  },
  {
    id: "checkbox",
    type: "checkbox",
    color: "cyan",
    variant: "flushed",
    direction: "row",
    defaultValue: ["r", "g"],
    options: [
      {
        label: "Red",
        value: "r",
        color: "red",
      },
      {
        label: "Green",
        value: "g",
        color: "green",
      },
      {
        label: "Pink",
        value: "p",
      },
      {
        label: "Yellow",
        value: "y",
        color: "yellow",
      },
    ],
  },
  {
    id: "textArea",
    label: "Text area",
    type: "textArea",
    variant: "flushed",
    color: "pink",
  },
  {
    id: "color",
    label: "Color",
    type: "color",
    variant: "flushed",
  },
  {
    id: "time",
    label: "Time",
    type: "time",
    variant: "flushed",
    required: true,
  },
];

interface IProps {
  // configGenericForm: any;
  formId: string;
  title?: string;
  inputs: any[];
  onlyInputs: boolean;
  initData?: any;
  handleAction?: (data: any) => void;
  actions?: any[];
}
const GenericForm = ({
  onlyInputs,
  title,
  initData,
  handleAction,
  inputs,
  actions,
  formId,
}: IProps) => {
  const variants = ["outline", "filled", "flushed", "unstyled"];
  const sizes = ["xs", "sm", "md", "lg"];
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [dataFrom, setDataForm] = useState<any>(null);
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isValid },
    control,
    getValues,
    watch,
  } = useForm();

  useEffect(() => {
    console.log("*_* efecWath");
    if (onlyInputs) {
      const dataFormValues = JSON.stringify(getValues());
      const dataFormLocal = JSON.stringify(dataFrom);
      if (dataFormValues !== dataFormLocal) {
        setDataForm(getValues());
        const dataForm = {
          isValid: isValid,
          data: getValues(),
        };
        // if (configGenericForm?.handleAction) {
        //   configGenericForm?.handleAction(dataForm);
        // }
        if (handleAction) {
          handleAction(dataForm);
        }
      }
    }
  }, [watch()]);

  const onSubmit = (values: any) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve("");
      }, 3000);
    });
  };

  const getElement = (element: any) => {
    switch (element.icon) {
      case "phone":
        return <PhoneIcon color={element.iconColor} />;
      case "check":
        return <CheckIcon color={element.iconColor} />;

      default:
        return element.icon;
    }
  };

  const getTypeInput = (id: string) => {
    const elementId: any = document.getElementById(id);
    return elementId?.type;
  };

  const getElementInput = (element: any) => {
    switch (element.inpRightElement.type) {
      case "buttonPassText":
        return (
          <Button
            id={`right-ele-button-${element.id}`}
            h="1.75rem"
            size="sm"
            onClick={() => {
              const elementId: any = document.getElementById(element.id);
              const elementButtonId: any = document.getElementById(
                `right-ele-button-${element.id}`
              );
              if (elementId?.type) {
                elementId.type =
                  elementId?.type === "text" ? "password" : "text";
                elementButtonId.innerText =
                  elementId?.type === "text"
                    ? element.inpRightElement.password
                    : element.inpRightElement.text;
              }
            }}
          >
            {element.inpRightElement.text}
          </Button>
        );
      case "buttonPassIcon":
        return (
          <Box
            cursor={"pointer"}
            onClick={() => {
              const elementId: any = document.getElementById(element.id);
              const elementButtonId: any = document.getElementById(
                `right-ele-button-${element.id}`
              );
              if (elementId?.type) {
                elementId.type =
                  elementId?.type === "text" ? "password" : "text";
                elementButtonId.innerText =
                  elementId?.type === "text"
                    ? element.inpRightElement.password
                    : element.inpRightElement.text;
              }
            }}
          >
            {/* <IconButton
            aria-label="open menu"
            icon={<FiEye />}
          /> */}
            {element.inpRightElement.text ? <FiEye /> : <FiEyeOff />}
          </Box>

          // <Button
          //   id={`right-ele-button-${element.id}`}
          //   h="1.75rem"
          //   size="sm"
          //   onClick={() => {
          //     const elementId: any = document.getElementById(element.id);
          //     const elementButtonId: any = document.getElementById(
          //       `right-ele-button-${element.id}`
          //     );
          //     if (elementId?.type) {
          //       elementId.type =
          //         elementId?.type === "text" ? "password" : "text";
          //       elementButtonId.innerText =
          //         elementId?.type === "text"
          //           ? element.inpRightElement.password
          //           : element.inpRightElement.text;
          //     }
          //   }}
          // >
          //   {element.inpRightElement.text}

          // </Button>
        );

      default:
        return element.type;
    }
  };

  return (
    <Container
      maxW={"4xl"}
      backgroundColor="white"
      pt="15px"
      pb="30px"
      borderRadius="15px"
    >
      <>
        {/* <FiEye />
        <FiEyeOff /> */}
        {title && <Text>{title}</Text>}
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          {inputs.map((item: any, idx: number) => (
            // {allInputs.map((item: any, idx: number) => (
            <FormControl
              key={`genericForm-${item.type || "text"}-${idx}`}
              id={item.id}
              isInvalid={!!errors[item.id]}
              mb="20px"
            >
              {item.label && <FormLabel mb={0}>{item.label}</FormLabel>}
              {/* <Text>Value: {item.label}</Text> */}
              {item.type === "select" ? (
                <Select
                  id={item.id}
                  color={item.color}
                  {...register(item.id, {
                    required: {
                      value: item.required,
                      message: item.requiredText,
                    },
                    value: initData[item.id] || item.defaultValue,
                  })}
                  placeholder={item.placeholder}
                  size={item.size}
                  variant={item.variant}
                  disabled={item.disabled}
                >
                  {/* <option value=""></option> */}
                  {item.options?.map((itemSelect: any, idxSelect: number) => (
                    <option
                      key={`${item.type}-${idxSelect}`}
                      value={itemSelect.value}
                    >
                      {itemSelect.label}
                    </option>
                  ))}
                </Select>
              ) : item.type === "switch" ? (
                <div
                  style={{
                    display: item.textPosition === "top" ? "" : "flex",
                    alignItems: "center",
                  }}
                >
                  {item.textPosition === "top" && (
                    <FormLabel htmlFor={item.id} mb="0">
                      {item.text}
                    </FormLabel>
                  )}
                  {item.textPosition === "left" && (
                    <FormLabel htmlFor={item.id}>{item.text}</FormLabel>
                  )}
                  <Switch
                    id={item.id}
                    {...register(item.id, {
                      required: {
                        value: item.required,
                        message: item.requiredText,
                      },
                      value: initData[item.id] || item.defaultValue,
                      minLength: {
                        value: item.minLength,
                        message: item.minLengthText,
                      },
                      maxLength: {
                        value: item.maxLength,
                        message: item.maxLengthText,
                      },
                      min: {
                        value: item.min,
                        message: item.minText,
                      },
                      max: {
                        value: item.max,
                        message: item.maxText,
                      },
                      pattern: {
                        value: item.pattern,
                        message: item.patternText,
                      },
                    })}
                    disabled={item.disabled}
                  />
                  {item.textPosition === "right" && (
                    <FormLabel htmlFor={item.id} ml={5}>
                      {item.text}
                    </FormLabel>
                  )}
                </div>
              ) : item.type === "radio" ? (
                <RadioGroup
                  defaultValue={initData[item.id] || item.defaultValue}
                >
                  <Stack spacing={3} direction={item.direction}>
                    {item.options?.map((itemRadio: any, idxRadio: number) => (
                      <Radio
                        isDisabled={itemRadio.disabled || item.disabled}
                        key={`${item.type}-${idxRadio}`}
                        {...register(item.id, {
                          required: {
                            value: item.required,
                            message: item.requiredText,
                          },
                        })}
                        value={itemRadio.value}
                        colorScheme={itemRadio.color || item.color}
                      >
                        {itemRadio.label}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
              ) : item.type === "pin" ? (
                <Controller
                  name={item.id}
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <PinInput
                      {...rest}
                      mask={item.mask}
                      otp={item.otp}
                      type={item.subType || "number"}
                      variant={item.variant}
                      size={item.size}
                      defaultValue={initData[item.id] || item.defaultValue}
                      placeholder={item.placeholder}
                      manageFocus={item.manageFocus}
                      isDisabled={item.disabled}
                    >
                      {[...Array(item.numberPin)].map(
                        (itemPin: any, idxPin: number) => (
                          <PinInputField
                            key={`${item.type}-${idxPin}`}
                            color={item.color}
                          />
                        )
                      )}
                    </PinInput>
                  )}
                  rules={{
                    required: {
                      value: item.required,
                      message: item.requiredText,
                    },
                    minLength: {
                      value: item.minLength,
                      message: item.minLengthText,
                    },
                    maxLength: {
                      value: item.maxLength,
                      message: item.maxLengthText,
                    },
                    pattern: {
                      value: item.pattern,
                      message: item.patternText,
                    },
                  }}
                />
              ) : item.type === "checkbox" ? (
                <Controller
                  name={item.id}
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <CheckboxGroup
                      {...rest}
                      defaultValue={initData[item.id] || item.defaultValue}
                    >
                      <Stack spacing={5} direction={item.direction}>
                        {item.options?.map(
                          (itemCheck: any, idxCheck: number) => (
                            <Checkbox
                              key={`${item.type}-${idxCheck}`}
                              value={itemCheck.value}
                              size={itemCheck.size || item.size}
                              colorScheme={itemCheck.color || item.color}
                              isChecked
                              isDisabled={itemCheck.disabled || item.disabled}
                            >
                              {itemCheck.label}
                            </Checkbox>
                          )
                        )}
                      </Stack>
                    </CheckboxGroup>
                  )}
                  rules={{
                    required: { value: true, message: "es es requerido" },
                  }}
                />
              ) : item.type === "textArea" ? (
                <Textarea
                  size={item.size}
                  variant={item.variant}
                  color={item.color}
                  disabled={item.disabled}
                  {...register(item.id, {
                    required: {
                      value: item.required,
                      message: item.requiredText,
                    },
                    value: initData[item.id] || item.defaultValue,
                    minLength: {
                      value: item.minLength,
                      message: item.minLengthText,
                    },
                    maxLength: {
                      value: item.maxLength,
                      message: item.maxLengthText,
                    },
                    min: {
                      value: item.min,
                      message: item.minText,
                    },
                    max: {
                      value: item.max,
                      message: item.maxText,
                    },
                    pattern: {
                      value: item.pattern,
                      message: item.patternText,
                    },
                  })}
                  placeholder={item.placeholder}
                />
              ) : item.type === "label" ? (
                <Text
                  {...register(item.id, {
                    value: initData[item.id] || item.defaultValue,
                  })}
                  style={{
                    borderBottom:
                      "1px solid var(--chakra-colors-chakra-border-color)",
                    height: "40px",
                  }}
                >
                  {initData[item.id] || item.defaultValue}
                </Text>
              ) : (
                <InputGroup
                  size={item.size}
                  variant={item.variant}
                  color={item.color}
                >
                  {item.leftElement && (
                    <InputLeftElement
                      pointerEvents="none"
                      color={item.leftElement.color}
                      fontSize={item.leftElement.fontSize}
                      children={getElement(item.leftElement)}
                    />
                  )}

                  {item.leftAddon && (
                    <InputLeftAddon
                      pointerEvents="none"
                      fontSize={item.leftAddon.fontSize}
                      color={item.leftAddon.color}
                      children={getElement(item.leftAddon)}
                    />
                  )}
                  <Input
                    type={item.type}
                    disabled={item.disabled}
                    {...register(item.id, {
                      required: {
                        value: item.required,
                        message: item.requiredText,
                      },
                      value: initData[item.id] || item.defaultValue,
                      minLength: {
                        value: item.minLength,
                        message: item.minLengthText,
                      },
                      maxLength: {
                        value: item.maxLength,
                        message: item.maxLengthText,
                      },
                      min: {
                        value: item.min,
                        message: item.minText,
                      },
                      max: {
                        value: item.max,
                        message: item.maxText,
                      },
                      pattern: {
                        value: item.pattern,
                        message: item.patternText,
                      },
                    })}
                    placeholder={item.placeholder}
                  />
                  {item.rightAddon && (
                    <InputRightAddon
                      pointerEvents="none"
                      color={item.rightAddon.color}
                      fontSize={item.rightAddon.fontSize}
                      children={getElement(item.rightAddon)}
                    />
                  )}

                  {item.rightElement && (
                    <InputRightElement
                      pointerEvents="none"
                      color={item.rightElement.color}
                      fontSize={item.rightElement.fontSize}
                      children={getElement(item.rightElement)}
                    />
                  )}

                  {item.inpRightElement && (
                    <InputRightElement
                      width={
                        item.inpRightElement.type === "buttonPassText"
                          ? "4.5rem"
                          : undefined
                      }
                    >
                      {getElementInput(item)}
                    </InputRightElement>
                  )}
                </InputGroup>
              )}
              {item.helperText && (
                <FormHelperText>{item.helperText}</FormHelperText>
              )}
              <FormErrorMessage>
                {String(errors[item.id]?.message)}
              </FormErrorMessage>
            </FormControl>
          ))}
          {actions?.map((itemAction: any, idxAction) => (
            <Button
              key={`genericForm-action-${idxAction}`}
              mt={4}
              colorScheme="teal"
              isLoading={isSubmitting}
              type={itemAction.type || "button"}
              onClick={() => {
                if (itemAction.handleAction) {
                  itemAction.handleAction(getValues());
                } else if (handleAction) {
                  handleAction(getValues());
                }
              }}
            >
              {itemAction.title}
            </Button>
          ))}
        </form>
      </>
    </Container>
  );
};

export default GenericForm;
