import { Navigate, Outlet } from "react-router-dom";
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes" });

const ProtectedRouteHome = () => {
  const user = ls.get("dUaStEaR");
  if (!user) {
    return <Navigate to="/login" />;
  }
  return <Outlet />;
};

export default ProtectedRouteHome;
