import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  IconButton,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaEye } from "react-icons/fa";

interface IProps {
  configTable: any;
  dataTable: any[];
  handleCrudTable: (data: { action: any; data: any }) => void;
}

const GeneralTable = ({ configTable, dataTable, handleCrudTable }: IProps) => {
  console.log("*_* configTable: ", configTable);

  const getColorBadge = (cadena: string) => {
    if (configTable.badges) {
      const finded = configTable.badges.find(
        (item: any) => item.key === cadena
      );
      return finded.color;
    } else {
      return "green";
    }
  };

  return (
    <TableContainer
      bg={useColorModeValue("white", "gray.900")}
      borderRadius="15px"
    >
      <Table variant="simple">
        {configTable.tableCaption && dataTable?.length > 0 ? (
          <TableCaption>{configTable.tableCaption}</TableCaption>
        ) : null}
        {configTable.tableCaptionLenght0 && dataTable?.length === 0 ? (
          <TableCaption>{configTable.tableCaptionLenght0}</TableCaption>
        ) : null}
        <Thead>
          <Tr>
            {configTable.columns?.map((item: any, index: number) => (
              <Th key={index}>{item.header.text}</Th>
            ))}
          </Tr>
        </Thead>

        <Tbody>
          {dataTable?.map((itemData: any, indexData: number) => (
            <Tr key={indexData}>
              {configTable.columns?.map(
                (itemColumn: any, indexColumn: number) => (
                  <Td key={`${indexData}-${indexColumn}`}>
                    {itemColumn.row.type === "badge" ? (
                      <Badge
                        colorScheme={getColorBadge(
                          itemData[itemColumn.row.keyValue]
                        )}
                      >
                        {itemData[itemColumn.row.keyValue]}
                      </Badge>
                    ) : itemColumn.row.type === "actions" ? (
                      itemColumn.row.actions.map(
                        (itemAction: any, indexAction: number) => (
                          <Box
                            key={indexAction}
                            display="inline-flex"
                            onClick={() => {
                              handleCrudTable({
                                action: itemAction,
                                data: itemData,
                              });
                            }}
                          >
                            <IconButton
                              variant="ghost"
                              colorScheme={
                                itemAction.color ||
                                (itemAction.key === "detail"
                                  ? "green"
                                  : itemAction.key === "edit"
                                  ? "blue"
                                  : itemAction.key === "delete"
                                  ? "red"
                                  : "")
                              }
                              aria-label="Call Segun"
                              size="sm"
                              icon={
                                itemAction.key === "detail" ? (
                                  <FaEye />
                                ) : itemAction.key === "edit" ? (
                                  <EditIcon />
                                ) : itemAction.key === "delete" ? (
                                  <DeleteIcon />
                                ) : (
                                  <></>
                                )
                              }
                            />
                          </Box>
                        )
                      )
                    ) : (
                      itemData[itemColumn.row.keyValue]
                    )}
                  </Td>
                )
              )}
            </Tr>
          ))}
        </Tbody>
        {/* <Tfoot>
          <Tr>
            <Th>To convert</Th>
            <Th>into</Th>
            <Th isNumeric>multiply by</Th>
          </Tr>
        </Tfoot> */}
      </Table>
    </TableContainer>
  );
};

export default GeneralTable;
