import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { systemConfigAction } from "../../../store/GlobalData";
import { IGeneralAlertProps } from "../../interfaces/IGeneralAlert";
import GeneralAlert from "../../molecules/GeneralAlert";
import GeneralDrawer from "../../molecules/GeneralDrawer";
import GeneralModal from "../../molecules/GeneralModal";

const StyleWrapperPage = styled((props: any) => <div {...props} />)`
  width: 100%;
  height: 100vh;
`;

interface IProps {
  children?: any;
}

const WrapperPage = ({ children }: IProps) => {
  const [generalAlert, setGeneralAlert] = useState<IGeneralAlertProps>();

  useEffect(() => {
    console.log("*_* effect wrapper");
    
  }, [])

  return (
    <StyleWrapperPage>
      {/* {React.cloneElement(children, {
        toggleLoading: onOpen,
      })} */}
      {children}
      <GeneralAlert />
      <GeneralModal />
      <GeneralDrawer />
    </StyleWrapperPage>
  );
};

export default WrapperPage;
