// const dataInitial = {
//   generalAlert: null,
// };
const dataInitial = {
  generalAlert: null,
  generalModal: null,
  generalDrawer: null,
  systemConfig: null,
};

const GENERAL_ALERT = "GENERAL_ALERT";
const GENERAL_MODAL = "GENERAL_MODAL";
const GENERAL_DRAWER = "GENERAL_DRAWER";
const SYSTEM_CONFIG = "SYSTEM_CONFIG";

const globalDataReducer = (state = dataInitial, action: any) => {
  switch (action.type) {
    case GENERAL_ALERT:
      return { ...state, generalAlert: action.payload };
    case GENERAL_MODAL:
      return { ...state, generalModal: action.payload };
    case GENERAL_DRAWER:
      return { ...state, generalDrawer: action.payload };
    case SYSTEM_CONFIG:
      return { ...state, systemConfig: action.payload };

    default:
      return state;
  }
};

export const generalAlertAction =
  (data: any): any =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: GENERAL_ALERT,
      payload: data,
    });
  };

export const generalModalAction =
  (data: any): any =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: GENERAL_MODAL,
      payload: data,
    });
  };

export const generalDrawerAction =
  (data: any): any =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: GENERAL_DRAWER,
      payload: data,
    });
  };

export const systemConfigAction =
  (data: any): any =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: SYSTEM_CONFIG,
      payload: data,
    });
  };
export default globalDataReducer;
