import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IGeneralAlertProps } from "../../interfaces/IGeneralAlert";
import { useSelector, useDispatch } from "react-redux";
import { generalAlertAction } from "../../../store/GlobalData";

const GeneralAlert = () => {
  const dispatch = useDispatch();
  const [genAlertLocal, setGenAlertLocal] = useState<any>(null);
  // let genAlertLocal: any = null;
  const generalAlert = useSelector(
    (store: any) => store.globalData?.generalAlert
  );

  useEffect(() => {
    console.log("*_* generalAlert: ", generalAlert, !!generalAlert);
    setGenAlertLocal(generalAlert);
  }, [generalAlert]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: any = React.useRef();

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={() => {
        if (genAlertLocal?.actionClose) {
          genAlertLocal.actionClose();
        } else {
          dispatch(generalAlertAction(null));
        }
      }}
      isOpen={!!genAlertLocal}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>{genAlertLocal?.title}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>{genAlertLocal?.text}</AlertDialogBody>
        <AlertDialogFooter>
          {genAlertLocal?.actions?.length > 0
            ? genAlertLocal?.actions.map((item: any, index: number) => (
                <Button
                  key={`genAlertLocal-button-${index}`}
                  isLoading={item.isLoading}
                  loadingText="Loading..."
                  spinnerPlacement="end"
                  colorScheme={item.color}
                  ml={3}
                  onClick={() => {
                    const tmpData = JSON.parse(JSON.stringify(genAlertLocal))
                    tmpData.actions[index].isLoading = true;
                    setGenAlertLocal(tmpData);
                    if (item.handleAction) {
                      item.handleAction(item);
                    }
                  }}
                >
                  {item.title}
                </Button>
              ))
            : null}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default GeneralAlert;
