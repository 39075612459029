import { Navigate, Outlet } from "react-router-dom";
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes" });

const ProtectedRouteLogin = () => {
  const user = ls.get("dUaStEaR");
  if (user) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

export default ProtectedRouteLogin;
