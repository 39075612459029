import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import { IGeneralAlertProps } from "../../interfaces/IGeneralAlert";
import { useSelector, useDispatch } from "react-redux";
import { generalDrawerAction } from "../../../store/GlobalData";
import GenericForm from "../../organisms/GenericForm";
import React, { useEffect, useState } from "react";

const GeneralDrawer = () => {
  const sizes = ["xs", "sm", "md", "lg", "xl", "full"];
  const firstField = React.useRef();
  const [dataForm, setDataForm] = useState<any>(null);
  const [genDrawerLocal, setGenDrawerLocal] = useState<any>(null);

  const dispatch = useDispatch();
  const generalDrawer = useSelector(
    (store: any) => store.globalData?.generalDrawer
  );

  useEffect(() => {
    console.log("*_* generalDrawer: ", generalDrawer, !!generalDrawer);
    setGenDrawerLocal(generalDrawer);
  }, [generalDrawer]);

  const handleActionGenericForm = (dataForm: any) => {
    setDataForm(dataForm);
    console.log("*_* handleActionGenericForm: ", dataForm);
  };


  return (
    <Drawer
      isOpen={!!genDrawerLocal}
      size={genDrawerLocal?.size}
      placement={genDrawerLocal?.placement}
      // initialFocusRef={firstField}
      onClose={() => {
        if (genDrawerLocal.actionClose) {
          genDrawerLocal.actionClose();
        } else {
          dispatch(generalDrawerAction(null));
        }
      }}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">
          {genDrawerLocal?.title}
        </DrawerHeader>

        <DrawerBody>
          {genDrawerLocal?.type === "text" ? (
            <>{genDrawerLocal?.text}</>
          ) : genDrawerLocal?.type === "genericForm" ? (
            <GenericForm
              formId={genDrawerLocal?.configGenericForm?.formId}
              onlyInputs={genDrawerLocal?.configGenericForm?.onlyInputs}
              initData={genDrawerLocal?.configGenericForm?.initData}
              inputs={genDrawerLocal?.configGenericForm?.inputs}
              actions={genDrawerLocal?.configGenericForm?.actions}
              handleAction={handleActionGenericForm}
            />
          ) : genDrawerLocal?.type === "customComponent" ? (
            <>{genDrawerLocal?.component()}</>
          ) : (
            <>No se encontro el tipo de drawer</>
          )}
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px">
          {genDrawerLocal?.actions?.length > 0
            ? genDrawerLocal?.actions.map((item: any, index: number) => (
                <Button
                  form={item.formId || ""}
                  key={`generalModal-button-${index}`}
                  isLoading={item.isLoading}
                  loadingText="Loading..."
                  spinnerPlacement="end"
                  type={item.type || "button"}
                  colorScheme={item.color}
                  ml={3}
                  onClick={() => {
                    if (dataForm?.isValid) {
                      const tmpData = JSON.parse(JSON.stringify(genDrawerLocal))
                      tmpData.actions[index].isLoading = true;
                      setGenDrawerLocal(tmpData);
                    }
                    if (item.handleAction) {
                      item.handleAction();
                    } else if (genDrawerLocal.handleDrawer) {
                      genDrawerLocal.handleDrawer({action: item, data: dataForm});
                    }
                  }}
                >
                  {item.title}
                </Button>
              ))
            : null}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default GeneralDrawer;
