import { AddIcon } from "@chakra-ui/icons";
import { Box, Text, useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Logo } from "../../../Logo";
import SecureLS from "secure-ls";
import { jsonInsumos } from "./jsonInsumos";
import GeneralTable from "../../molecules/GeneralTable";
import CrudTable from "../../organisms/CrudTable";
import {
  generalDrawerAction,
  generalModalAction,
  generalAlertAction,
} from "../../../store/GlobalData";
import { apiTest } from "../../../services/test.service";

const dataDummy: any = {
  careers: [
    {
      id: 1,
      careerName: "Ingeniería en Sistemas Computacionales",
      status: "Activo",
    },
    {
      id: 2,
      careerName: "Gastronimia",
      status: "Inactivo",
    },
    {
      id: 3,
      careerName: "Ingeniería Civil",
      status: "Activo",
    },
  ],
  subjects: [
    {
      id: 1,
      subjectName: "Ecuaciones vectoriales",
      status: "Activo",
    },
    {
      id: 2,
      subjectName: "Administración",
      status: "Inactivo",
    },
    {
      id: 3,
      subjectName: "Calculo diferencial",
      status: "Activo",
    },
    {
      id: 4,
      subjectName: "Estadistica",
      status: "Activo",
    },
  ],
  teachers: [
    {
      id: 1,
      teacherName: "Carlos",
      teacherLastName: "Salinas",
      teacherSecondName: "Ruiz",
      age: "24",
      gender: "Masculino",
      status: "Activo",
    },
    {
      id: 2,
      teacherName: "Yordy",
      teacherLastName: "Cruz",
      teacherSecondName: "Cruz",
      age: "26",
      gender: "Masculino",
      status: "Inactivo",
    },
    {
      id: 3,
      teacherName: "Karen",
      teacherLastName: "Perez",
      teacherSecondName: "Arias",
      age: "28",
      gender: "Femenino",
      status: "Activo",
    },
    {
      id: 4,
      teacherName: "Yonathan David",
      teacherLastName: "Cruz",
      teacherSecondName: "Perez",
      age: "20",
      gender: "Masculino",
      status: "Activo",
    },
  ],
  users: [
    {
      id: 1,
      user: "",
      status: "Activo",
    },
  ],
};

const CrudTableContainer = () => {
  const ls = new SecureLS({ encodingType: "aes" });
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const systemConfig = useSelector(
    (store: any) => store.globalData?.systemConfig
  );
  console.log("*_* systemConfig: ", systemConfig);

  const [configModule, setConfigModule] = useState<any>();
  const [dataTable, setDataTable] = useState<any>();
  const params: any = useParams();
  // const jsonInsumosLocal: any = jsonInsumos;
  useEffect(() => {
    console.log("*_* configModule: ", systemConfig);
    if (systemConfig && systemConfig[params?.module]) {
      setConfigModule(systemConfig[params?.module]);
      setDataTable(dataDummy[params?.module]);
      // apiTest(200, )
    }
  }, [systemConfig, params]);

  const handleDrawerAdd = async (data: any) => {
    console.log("*_* handleDrawerAdd: ", data);
    if (data.data?.isValid) {
      const dataDummyAdd = {
        status: 200,
        message: "Se creó con exito",
      };
      const respApi = await apiTest(200, dataDummyAdd);
      if (respApi.status === 200) {
        dispatch(generalDrawerAction(undefined));
        toast({
          title: `Creado`,
          // description: "We've created your account for you.",
          status: "success",
          duration: 4000,
          position: "bottom-right",
          isClosable: true,
          variant: ["left-accent"],
        });
      } else {
      }
    } else {
      
    }
  };
  const handleDrawerEdit = async (data: any) => {
    console.log("*_* handleDrawerEdit: ", data);
    if (data.data?.isValid) {
      const dataDummyAdd = {
        status: 200,
        message: "Se creó con exito",
      };
      const respApi = await apiTest(200, dataDummyAdd);
      if (respApi.status === 200) {
        dispatch(generalDrawerAction(undefined));
        toast({
          title: `Editado`,
          // description: "We've created your account for you.",
          status: "success",
          duration: 4000,
          position: "bottom-right",
          isClosable: true,
          variant: ["left-accent"],
        });
      } else {
      }
    } else {
      
    }
  };
  const handleDrawerDetail = (data: any) => {
    console.log("*_* handleDrawerDetail: ", data);
    dispatch(generalDrawerAction(undefined));
  };
  const handleDrawerDelete = async (data: any) => {
    console.log("*_* handleDrawerDelete: ", data);
    if (data?.type === "delete") {
      const dataDummyDelete = {
        status: 200,
        message: "Se elimino con exito",
      };
      const respApi = await apiTest(200, dataDummyDelete);
      if (respApi.status === 200) {
        dispatch(generalAlertAction(undefined));
        toast({
          title: `Eliminado`,
          // description: "We've created your account for you.",
          status: "success",
          duration: 4000,
          position: "bottom-right",
          isClosable: true,
          variant: ["left-accent"],
        });
      } else {
      }
    } else if (data?.type === "cancel") {
      dispatch(generalAlertAction(undefined));
    }
  };

  const handleCrudTable = (data: any) => {
    console.log("*_* handleCrudTable: ", data, configModule);
    ls.set("selectedCrudTable", data);
    // navigate(`/${params?.module}/${data.type}`)
    if (data.action?.type === "add") {
      if (configModule.addForm) {
        dispatch(
          generalDrawerAction({
            title: configModule.addForm.title,
            type: "genericForm",
            configGenericForm: configModule.addForm.configForm,
            size: configModule.addForm.size || "md",
            placement: configModule.addForm.placement || "right",
            actions: configModule.addForm.actions,
            handleDrawer: handleDrawerAdd,
          })
        );
      } else {
        // no existe configuracion para addForm
      }
    } else if (data.action?.type === "detail") {
      if (configModule.detailForm) {
        dispatch(
          generalDrawerAction({
            title: configModule.detailForm.title,
            type: "genericForm",
            configGenericForm: {
              ...configModule.detailForm.configForm,
              initData: data.data,
            },
            size: configModule.detailForm.size || "md",
            placement: configModule.detailForm.placement || "right",
            actions: configModule.detailForm.actions,
            handleDrawer: handleDrawerDetail,
          })
        );
      } else {
        // no existe configuracion para addForm
      }
    } else if (data.action?.type === "edit") {
      if (configModule.editForm) {
        dispatch(
          generalDrawerAction({
            title: configModule.editForm.title,
            type: "genericForm",
            configGenericForm: {
              ...configModule.editForm.configForm,
              initData: data.data,
            },
            size: configModule.editForm.size || "md",
            placement: configModule.editForm.placement || "right",
            actions: configModule.editForm.actions,
            handleDrawer: handleDrawerEdit,
          })
        );
      } else {
        // no existe configuracion para addForm
      }
    } else if (data.action?.type === "delete") {
      dispatch(
        generalAlertAction({
          title: configModule.alertDelete?.title,
          text: configModule.alertDelete?.message,
          actions: [
            {
              title: "Cancelar",
              type: "cancel",
              handleAction: handleDrawerDelete,
            },
            {
              title: "Eliminar",
              color: "red",
              type: "delete",
              handleAction: handleDrawerDelete,
            },
          ],
        })
      );
    } else {
    }
    // dispatch(generalModalAction({
    //   title: "Agregar carrera",
    //   type: "genericForm",
    //   configGenericForm: {},
    //   text: "jajajajjajajja",
    //   actions: [
    //     {
    //       title: "Cancelar",
    //       handleAction: handleCancelarModal,
    //       color: "red",
    //     },
    //     {
    //       title: "Agregar",
    //       color: "green",
    //     }
    //   ]
    // }));
  };

  const handleCancelarModal = () => {
    dispatch(generalModalAction(null));
  };

  return (
    <Box textAlign="center" fontSize="xl" height="100%">
      {configModule ? (
        <CrudTable
          configTable={configModule.configTable}
          title={configModule.title}
          withAddButton={configModule.withAddButton}
          dataTable={dataTable}
          handleCrudTable={handleCrudTable}
        />
      ) : null}
    </Box>
  );
};

export default CrudTableContainer;
