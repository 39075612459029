import { AddIcon } from "@chakra-ui/icons";
import { Box, IconButton, Text } from "@chakra-ui/react";
import GeneralTable from "../../molecules/GeneralTable";

interface IProps {
  title?: string;
  withAddButton?: boolean;
  configTable: any;
  dataTable: any;
  handleCrudTable: (data: any) => void;
}

const CrudTable = ({
  dataTable,
  configTable,
  handleCrudTable,
  title,
  withAddButton,
}: IProps) => {
  return (
    <>
      {title ? (
        <Text mb="10px" fontSize="30px" fontWeight="bold" textAlign="start">
          {title}
        </Text>
      ) : null}
      {withAddButton ? (
        <Box display="flex" justifyContent="right">
          <IconButton
            colorScheme="teal"
            aria-label="Call Segun"
            size="lg"
            icon={<AddIcon />}
            onClick={() => handleCrudTable({ action: { type: "add" }, data: null })}
          />
        </Box>
      ) : null}
      <GeneralTable configTable={configTable} dataTable={dataTable} handleCrudTable={handleCrudTable}/>
    </>
  );
};

export default CrudTable;
